























































































import { ref, reactive, toRefs, computed, defineComponent, Ref } from '@vue/composition-api';
import { ObjectId } from 'bson';

import { useDbGetters } from '@/store';
import Bar from './Bar.vue';
import ListView from './components/TableView.vue';

export default defineComponent({
  name: 'ExploreGuide',

  components: {
    'guide-bar': Bar,
    ListView
  },

  setup(_props, ctx) {
    const { collection } = useDbGetters(['collection']);
    const employerData: Ref<any[]> = ref([]);
    const state = reactive({
      programFilter: 'All' as 'All' | 'Bookmarked',
      ageFilter: null as any,
      residenceFilter: null,
      pathwaysFilter: [{} as any],
      bookmarked: [] as ObjectId[],
      residenceOptions: [] as string[]
    });

    const savedBookmarks = localStorage.getItem('bookmarkedPrograms'); // * grab bookmarks from localStorage
    if (savedBookmarks) state.bookmarked = JSON.parse(savedBookmarks).map(id => new ObjectId(id));

    collection.value!('Program')
      .find({
        published: true
      })
      .then(programs => {
        programs.sort((a, b) => (a.programName || '').localeCompare(b.programName || ''));
        employerData.value = programs;
        programs.forEach(program => {
          if (program.requiredResidency) state.residenceOptions.push(...program.requiredResidency);
        });
      });

    if (ctx.root.$route.query) {
      let query = ctx.root.$route.query.pathway;
      if (!Array.isArray(query)) query = [query];
      query = query.filter(word => !!word);
      if (!query.length)
        state.pathwaysFilter = [
          {
            text: 'All',
            color: 'grey darken-4'
          }
        ];
      else
        state.pathwaysFilter = query.map(pathway => ({
          text: pathway as string,
          color: 'grey darken-4'
        }));
    }

    const currentUnit = ref(ListView);

    const filteredPrograms = computed(() => {
      let visiblePrograms;
      if (state.programFilter === 'Bookmarked') {
        visiblePrograms = employerData.value.filter((program: Record<string, any>) =>
          state.bookmarked.some((id: ObjectId) => id.equals(program._id))
        );
      } else {
        visiblePrograms = employerData.value;
      }

      if (state.residenceFilter && state.residenceFilter !== 'None') {
        visiblePrograms = visiblePrograms.filter(
          program =>
            program.requiredResidency && program.requiredResidency.includes(state.residenceFilter)
        );
      }

      if (state.ageFilter && state.ageFilter !== 'All') {
        visiblePrograms = visiblePrograms.filter(
          program =>
            program.ageRange[0] <= parseInt(state.ageFilter, 10) &&
            program.ageRange[1] >= parseInt(state.ageFilter, 10)
        );
      }

      if (state.pathwaysFilter.length && !state.pathwaysFilter.some(obj => obj.text === 'All')) {
        const filters = state.pathwaysFilter.map(obj => obj.text);
        visiblePrograms = visiblePrograms.filter(
          program => program.pathways && program.pathways.some(pathway => filters.includes(pathway))
        );
      }

      return visiblePrograms;
    });

    const bookmarkProgram = (programId: ObjectId) => {
      if (state.bookmarked.some((id: ObjectId) => id.equals(programId)))
        state.bookmarked = state.bookmarked.filter((id: ObjectId) => !id.equals(programId));
      else state.bookmarked.push(programId);
      localStorage.setItem('bookmarkedPrograms', JSON.stringify(state.bookmarked)); //* save bookmarks to localStorage
    };
    // const announcement5 = ref(true);

    return {
      ...toRefs(state),
      filteredPrograms,
      bookmarkProgram,
      currentUnit,
      // announcement5,
      snackbar: true
    };
  }
});
