






import { useDbGetters, useDbState, useUserGetters } from '@/store';
import { watchEffect } from '@vue/composition-api';
import activitiesList from './const';

export default {
  props: {
    activityName: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(_props, ctx: any) {
    const { collection } = useDbGetters(['collection']);
    const { user }: any = useDbState(['user']);
    const { activityName }: any = _props;
    function redirect() {
      collection.value!('Student')
        .findOne({ participant_id: user.value?._id }, { sort: { created: -1 } })
        .then(res => {
          if (res) {
            const adks = activitiesList;
            let index = 0;
            if (adks.includes(activityName.toLowerCase())) {
              index = adks.indexOf(activityName.toLowerCase());
            }
            console.log(index, 'index');
            ctx.root.$router.push({
              name: 'guide',
              params: { programId: res.program_id.toString(), page: index }
            });
          } else {
            ctx.root.$router.push({ name: 'portfolio' });
          }
        });
    }

    watchEffect(() => {
      if (user.value?._id && activityName) {
        redirect();
      }
    });

    return { redirect };
  }
};
