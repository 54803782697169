




































































































































































































































































































































































































































































































import { ref, defineComponent, PropType } from '@vue/composition-api';
import USlocations from '@/constants/us-residencies';
import { BAR_SEQUENCE, BAR_ITEMS2, BAR_AGES, BAR_PATHWAYS } from '@/constants/explore';

export default defineComponent({
  name: 'Bar',
  props: {
    programFilter: {
      required: true,
      type: String
    },
    pathwaysFilter: {
      required: true,
      type: Array as PropType<Array<Record<string, any>>>
    },
    residenceOptions: {
      required: true,
      type: Array
    }
  },

  setup(props, ctx) {
    function logThis(val: string) {
      console.log(val);
    }
    const vertical = ref(true);
    const expand = ref(true); // open or closed sidebar
    const steps = ref(15); // number of lines
    const activeStep = ref(1); // open line
    const actions = ref([
      {
        icon: 'account-group',
        fn: 'my-portfolio'
      },
      {
        icon: 'message-outline',
        fn: 'my-chat'
      },
      {
        icon: 'square-edit-outline',
        fn: 'my-post'
      },
      {
        icon: 'cog',
        fn: 'my-settings'
      }
    ]);
    const sequence = ref(BAR_SEQUENCE);
    const handlePathwaysFilterInput = val => {
      if (val.length > 1 && val[val.length - 1].text === 'All')
        ctx.emit(
          'update:pathwaysFilter',
          val.filter(filter => filter.text === 'All')
        );
      else if (val.length > 1)
        ctx.emit(
          'update:pathwaysFilter',
          val.filter(filter => filter.text !== 'All')
        );
      else ctx.emit('update:pathwaysFilter', val);
    };
    return {
      handlePathwaysFilterInput,
      steps,
      expand,
      activeStep,
      sequence,
      actions,
      logThis,
      vertical
    };
  },
  data: () => ({
    activator: null,
    attach: null,
    colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
    editing: null,
    editingIndex: -1,
    sortitems: [
      { title: 'School' },
      { title: 'Grade' },
      { title: 'Team' },
      { title: 'Completion' }
    ],
    items2: BAR_ITEMS2,

    pathwaysStandard: [
      {
        text: 'All Employers',
        color: 'grey darken-4'
      }
    ],

    citizen: [
      {
        text: 'Student',
        color: 'grey darken-4'
      },
      {
        text: 'Teacher',
        color: 'grey darken-4'
      },
      {
        text: 'Parent',
        color: 'grey darken-4'
      },
      {
        text: 'School',
        color: 'grey darken-4'
      },
      {
        text: 'Employer',
        color: 'grey darken-4'
      },
      {
        text: 'Sponsor',
        color: 'grey darken-4'
      }
    ],
    citizenStandard: {
      text: 'Student',
      color: 'grey darken-4'
    },
    city: USlocations.US_LOCATIONS,
    age: BAR_AGES,

    pathways: BAR_PATHWAYS,

    nonce: 1,
    menu: false,
    model: [],
    x: 0,
    search: null,
    y: 0
  }),
  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = val => (val != null ? val : '');

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
    }
  }
});
