var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"explore-card__card transition-swing",attrs:{"elevation":hover ? 10 : 0}},[_c('div',{staticClass:"pl-5 pt-5 pr-5"}),(_vm.isExternShip)?_c('v-btn',{staticClass:"explore-card__button font-weight-bold ml-3 mr-1 mb-1",attrs:{"rounded":"","small":"","readonly":"","depressed":"","outlined":"","color":_vm.color,"ripple":false}},[_vm._v(" Externship only ")]):_vm._e(),_c('div',{class:("explore-card__employer-title-" + _vm.color)},[_vm._v(_vm._s(_vm.employer.programName))]),_c('v-card',{attrs:{"flat":"","tile":"","color":_vm.color}},[_c('div',{staticClass:"explore-card__text-background"},[_vm._v(" "+_vm._s(_vm.employer.programDesc)+" "),_c('div',{staticClass:"mt-4"},_vm._l((_vm.employer.pathways),function(pathway){return _c('div',{key:pathway,staticClass:"mr-2 mb-2 explore-card__pathway-chip",attrs:{"color":"white","outlined":"","small":""}},[_vm._v(" "+_vm._s(pathway)+" ")])}),0)])]),_c('v-list-item',{staticClass:"justify-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.bookmarkProgram}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.bookmarked ? '#fec34b' : 'grey'}},[_vm._v("mdi-bookmark")])],1)]}}],null,true)},[_c('span',[_vm._v("Bookmark")])])],1),_c('v-list-item',{staticClass:"flex-wrap"},[_c('v-btn',{staticClass:"explore-card__button font-weight-bold ml-1 mr-1",attrs:{"rounded":"","small":"","disabled":"","depressed":"","ripple":false}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-set-none")]),_vm._v(_vm._s(_vm.employer.tokens)+"1 Token ")],1),(_vm.isProgramStarted)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
          name: 'guide',
          params: {
            programId: _vm.employer._id.toString(),
            page: 0
          }
        }}},[_c('v-btn',{staticClass:"explore-card__button font-weight-bold ml-1 mr-1",attrs:{"dark":"","rounded":"","small":"","depressed":"","ripple":false}},[_vm._v("Continue Program")])],1):(_vm.isOrganizer)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
          name: 'Monitor',
          query: {
            program: _vm.employer._id.toString()
          }
        }}},[_c('v-btn',{staticClass:"explore-card__button font-weight-bold ml-1 mr-1",attrs:{"outlined":"","rounded":"","small":"","depressed":"","ripple":false}},[_vm._v("Monitor Program")])],1):_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
          name: 'guide',
          params: {
            programId: _vm.employer._id.toString(),
            page: 0
          },
          query: {
            preview: 'true'
          }
        }}},[_c('v-btn',{staticClass:"explore-card__button font-weight-bold ml-1 mr-1",attrs:{"outlined":"","rounded":"","small":"","depressed":"","ripple":false}},[_vm._v("Start Program")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }