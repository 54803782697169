












































































































































import { defineComponent, PropType, computed } from '@vue/composition-api';
import { ObjectId } from 'bson';
import { useDbState } from '@/store';

const colors = ['red', 'purple', 'orange', 'blue', 'green', 'pink', 'yellow'];

export default defineComponent({
  name: 'ExploreCard',
  props: {
    employer: {
      required: true,
      type: Object as PropType<Record<string, any>>
    },
    bookmarks: {
      required: true,
      type: Array as PropType<Array<ObjectId>>
    }
  },
  setup(props, ctx) {
    const { user } = useDbState(['user']);
    const color = colors[Math.floor(Math.random() * colors.length)];
    const bookmarked = computed(() =>
      props.bookmarks.some((id: ObjectId) => id.equals(props.employer._id))
    );
    const isProgramStarted = computed(() => {
      return props.employer?.participants.some((id: ObjectId) => id.equals(user.value?._id));
    });
    const isOrganizer = computed(() => {
      return (
        props.employer?.organizers.some((id: ObjectId) => id.equals(user.value?._id)) &&
        props.employer.published
      );
    });
    const isExternShip = computed(() => {
      return props.employer?.adks.filter(a => a.name === 'offer')[0]?.offer[0]?.externShip;
    });
    const bookmarkProgram = () => {
      ctx.emit('bookmarkProgram', props.employer._id);
    };
    return { color, bookmarkProgram, bookmarked, isProgramStarted, isOrganizer, isExternShip };
  }
});
