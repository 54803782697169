var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"guide-bar"},[_c('v-card',{staticClass:"program-card"},[_c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expand),expression:"expand"}],staticClass:"guide-bar__container"},[_c('v-list',{staticClass:"guide-bar__list"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"x-large":"","depressed":"","icon":""},on:{"click":function($event){return _vm.$router.push({ name: 'landing' })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black","x-large":""}},[_vm._v("mdi-home")])],1)]}}])},[_c('span',[_vm._v("Home")])]),_c('div',{},[_c('v-btn',{staticClass:"font-weight-bold guide-bar__activities",attrs:{"x-small":"","rounded":"","depressed":"","disabled":""}},[_vm._v("Build projects to win internships")])],1),_c('div',{staticClass:"guide-bar__explore-participants-title mt-5 mb-3"},[_vm._v(" Explore "),_c('br'),_vm._v("Employers ")]),_c('div',{staticClass:"guide-bar__explore-filter-btn pb-2 mb-3",class:_vm.$vuetify.breakpoint.xs ? 'pl-0' : 'pl-2'},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-large":""},on:{"click":function($event){return _vm.$emit('update:programFilter', 'All')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.programFilter === 'All' ? '#6fc284' : 'grey'}},[_vm._v("mdi-sign-direction")])],1)]}}])},[_c('span',[_vm._v("All Employers")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-large":""},on:{"click":function($event){return _vm.$emit('update:programFilter', 'Bookmarked')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.programFilter === 'Bookmarked' ? '#fec34b' : 'grey'}},[_vm._v("mdi-bookmark")])],1)]}}])},[_c('span',[_vm._v("Bookmarked")])])],1),_c('div',{staticClass:"mb-12",class:_vm.$vuetify.breakpoint.xs ? 'pl-0' : 'pl-3 '},[_c('v-chip',{staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"grey darken-1","depressed":""}},[_vm._v("Activities include")]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","outlined":"","color":"grey darken-1","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-rocket-outline")]),_vm._v("Start Program")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Qualify, checkout and start program")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"#3c9dcd","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-book-open")]),_vm._v("Scope")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Review project scope")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"#ae90b0","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-discord")]),_vm._v("Community")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Join and engage in our online student community")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"#eda1bf","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-supervisor-circle")]),_vm._v("Team")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Join, create or manage project teams")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"#6eba80","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-teach")]),_vm._v("Train")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Watch employer-made video content")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"#ea6764","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-bookmark-multiple")]),_vm._v("Research")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Research employer-provided resources")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"#f79961","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-timetable")]),_vm._v("Tinker")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Tinker, practice and log time spent on project")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"#fec34b","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-head-flash-outline")]),_vm._v("Ideate")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Brainstorm, answer and iterate on your project idea")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"#3c9dcd","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-elevator-passenger")]),_vm._v("Pitch")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Write your one sentence and elevator pitches")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"#ea6764","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-comment-text-multiple-outline")]),_vm._v("Forum")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Ask and answer questions with your program peers")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"#6eba80","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-feather")]),_vm._v("Make")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Design, prototype and log your project milestones")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"#eda1bf","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-telegram")]),_vm._v("Demo")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Demonstrate and present your project by recording a video")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"#f79961","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-check-all")]),_vm._v("Auto-Apply")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Review completed activities and auto-apply for internship")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"#3c9dcd","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-supervisor-circle-outline")]),_vm._v("Interview")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Interview asynchronously with employer")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","color":"purple","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-offer")]),_vm._v("Offer")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("Accept or decline anticipated position offer")])]),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"guide-bar__activities-chips font-weight-bold",attrs:{"rounded":"","dark":"","outlined":"","color":"grey darken-1","depressed":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-balloon")]),_vm._v("Program Results")],1)]}}])},[_c('span',{staticClass:"d-flex font-weight-bold"},[_vm._v("View your end of program results")])])],1),_c('v-combobox',{staticClass:"guide-bar__combobox mt-4 mb-4",class:_vm.$vuetify.breakpoint.md ? 'mx-4' : '',attrs:{"value":_vm.pathwaysFilter,"filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.pathways,"search-input":_vm.search,"label":"Filter by Pathways","multiple":"","hide-selected":"","prepend-inner-icon":"mdi-sign-direction","rounded":"","small-chips":"","hide-details":"","outlined":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":function($event){return _vm.handlePathwaysFilterInput($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({class:'explore-dropdown',attrs:{"color":("" + (item.color)),"input-value":selected,"dark":"","x-large":_vm.$vuetify.breakpoint.xs ? true : false}},'v-chip',attrs,false),[_c('v-icon',{attrs:{"large":_vm.$vuetify.breakpoint.xs ? true : false,"left":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" mdi-close ")]),_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(item.text))])],1):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(_vm.editing === item)?_c('v-text-field',{attrs:{"autofocus":"","flat":"","background-color":"transparent","hide-details":"","solo":"","x-large":_vm.$vuetify.breakpoint.xs ? true : false},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item)}},model:{value:(_vm.editing.text),callback:function ($$v) {_vm.$set(_vm.editing, "text", $$v)},expression:"editing.text"}}):_c('v-chip',{class:'explore-dropdown',attrs:{"x-large":_vm.$vuetify.breakpoint.xs ? true : false,"color":("" + (item.color)),"dark":"","label":""}},[_c('span',[_vm._v(_vm._s(item.text))])]),_c('v-spacer')]}}])}),_c('v-combobox',{staticClass:"guide-bar__combobox mt-4 mb-4",class:_vm.$vuetify.breakpoint.md ? 'mx-4' : '',attrs:{"rounded":"","filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.residenceOptions,"search-input":_vm.search,"hide-selected":"","prepend-inner-icon":"mdi-map-marker","label":"Filter by Priority Location","small-chips":"","hide-details":"","outlined":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":function (val) { return _vm.$emit('update:residenceFilter', val); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:'explore-dropdown',attrs:{"x-large":_vm.$vuetify.breakpoint.xs ? true : false,"color":("" + (item.color)),"dark":""}},[_c('span',[_vm._v(_vm._s(item))])])]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(_vm.editing === item)?_c('v-text-field',{attrs:{"autofocus":"","flat":"","background-color":"transparent","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item)}},model:{value:(_vm.editing.text),callback:function ($$v) {_vm.$set(_vm.editing, "text", $$v)},expression:"editing.text"}}):_c('v-chip',{staticClass:"explore-dropdown mb-2",attrs:{"x-large":_vm.$vuetify.breakpoint.xs ? true : false,"dark":"","label":""}},[_c('span',[_vm._v(_vm._s(item))])]),_c('v-spacer')]}}])})],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }