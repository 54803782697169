






import { useDbGetters, useDbState, useUserGetters } from '@/store';
import { watchEffect } from '@vue/composition-api';
import activitiesList from './const';

export default {
  setup(props, ctx: any) {
    const { collection } = useDbGetters(['collection']);
    const { user }: any = useDbState(['user']);

    function redirect() {
      collection.value!('Student')
        .findOne({ participant_id: user.value?._id }, { sort: { created: -1 } })
        .then(res => {
          if (res) {
            const setupAdks = res.adks.filter(
              adk =>
                adk.completed ||
                adk.isComplete ||
                adk.discordIntegraionSkipped ||
                adk.directoryPreferences
            );
            const mapedSetupAdks = setupAdks.map(adk => adk.name);

            const adks = activitiesList;
            let index = 0;
            for (let k = 0; k < adks.length; k++) {
              if (mapedSetupAdks.includes(adks[k])) {
                index = adks.indexOf(adks[k]);
              }
            }
            ctx.root.$router.push({
              name: 'guide',
              params: { programId: res.program_id.toString(), page: `${index}` }
            });
          } else {
            ctx.root.$router.push({ name: 'portfolio' });
          }
        });
    }

    watchEffect(() => {
      if (user.value?._id) {
        redirect();
      }
    });

    return { redirect };
  }
};
