






import { useDbGetters, useDbState } from '@/store';
import { watchEffect } from '@vue/composition-api';
import { ObjectId } from 'bson';

export default {
  setup(_props, ctx: any) {
    const { collection } = useDbGetters(['collection']);
    const { user }: any = useDbState(['user']);
    function redirect() {
      collection.value!('Program')
        .findOne({ organizers: new ObjectId(user.value._id) }, { sort: { dateCreated: -1 } })
        .then(res => {
          if (res) {
            ctx.root.$router.push({ path: `/monitor?program=${res._id.toString()}` });
          } else {
            ctx.root.$router.push({ name: 'portfolio' });
          }
        });
    }

    watchEffect(() => {
      if (user.value?._id) {
        redirect();
      }
    });

    return { redirect };
  }
};
